import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

// PrivateRoute Component
const PrivateRoute = ({ element: Element, ...rest }) => {
  const token = localStorage.getItem('token'); // Check for authentication token
  const location = useLocation(); // Get the current location

  // Function to check if token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime;
    } catch (e) {
      return true; // If token decoding fails, consider it expired
    }
  };

  return !token || isTokenExpired(token) ? (
    <Navigate to="/login" state={{ from: location }} replace /> // Redirect to login if token is not present or expired
  ) : (
    <Element {...rest} /> // Render the protected component if authenticated and token is valid
  );
};

export default PrivateRoute;
