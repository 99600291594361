import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ActivityIndicator, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgetPassword = () => {
  const { width } = Dimensions.get('window');
  const navigate = useNavigate();
  
  const [email, setEmail] = useState('');
  const [serviceId, setServiceId] = useState('1');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResetStage, setIsResetStage] = useState(false);

  const handlePreResetPassword = () => {
    if (email.trim() === '') {
      toast.error('Email cannot be empty');
      return;
    }

    setLoading(true);
    let sitBaseUrl = 'https://digitalnexuspartners.ca/api'

    fetch(`${sitBaseUrl}/identity/customer/preResetPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, serviceId }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setTransactionId(data.transactionId);
        setIsResetStage(true);
        toast.success('OTP sent to your email');
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to send OTP');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetPassword = () => {
    let sitBaseUrl = 'https://digitalnexuspartners.ca/api'

    if (otp.trim() === '' || newPassword.trim() === '') {
      toast.error('OTP and new password cannot be empty');
      return;
    }

    setLoading(true);

    fetch(`${sitBaseUrl}/identity/customer/resetPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, serviceId, transactionId, otp, newPassword }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      //  return response.json(); // Ensure you handle the response
      })
      .then(() => {
        toast.success('Password reset successfully');
        // Wait 3 seconds before navigating to the login page
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to reset password');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{isResetStage ? 'Reset Password' : 'Forgot Password'}</Text>
      {!isResetStage ? (
        <View style={styles.formContainer}>
          <Text style={styles.label}>Email</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter your email address"
            keyboardType="email-address"
            value={email}
            onChangeText={setEmail}
          />
          <TouchableOpacity style={styles.button} onPress={handlePreResetPassword}>
            <Text style={styles.buttonText}>Send OTP</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.formContainer}>
          <Text style={styles.label}>OTP</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter OTP"
            value={otp}
            onChangeText={setOtp}
          />
          <Text style={styles.label}>New Password</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter new password"
            secureTextEntry
            value={newPassword}
            onChangeText={setNewPassword}
          />
          <TouchableOpacity style={styles.button} onPress={handleResetPassword}>
            <Text style={styles.buttonText}>Reset Password</Text>
          </TouchableOpacity>
        </View>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
      />
      {loading && <ActivityIndicator size="large" color="#133e8a" style={styles.loader} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 30,
    paddingTop: 50,
    paddingBottom: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 24,
    color: '#133e8a',
    fontWeight: 'bold',
  },
  formContainer: {
    width: '100%',
  },
  label: {
    marginBottom: 6,
    color: 'gray',
    fontSize: 16,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 8,
    fontSize: 16,
    color: 'black',
    width: '100%',
    borderRadius: 8,
    marginBottom: 12,
  },
  button: {
    backgroundColor: '#133e8a',
    paddingVertical: 12,
    paddingHorizontal: 24,
    marginTop: 20,
    width: '100%',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
  loader: {
    marginTop: 20,
  },
});

export default ForgetPassword;
