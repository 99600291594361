// Header.js

import React from 'react';
import './Header.css'; // Import your header styles
import { View, Text, StyleSheet, TouchableOpacity, TextInput, ScrollView } from 'react-native';


const Header = ({ title }) => {
  return (
    // <div className="header-container">
    //   <h1 className="header-title">{title}</h1>
    // </div>
    <View style={styles.headerContainer}>
    <Text style={styles.examTitle}>{title}</Text>
  </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: '#FFFFFF', // White background color for the header
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginBottom: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  examTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#17499F', // Blue color for the title text
  }
});

export default Header;
