import React, { useState } from 'react';
import { read, utils } from 'xlsx';
import { Text, View, ScrollView, StyleSheet, TouchableOpacity, Dimensions, TextInput } from 'react-native';

const { width } = Dimensions.get('window');

const ExcelViewerWithPagination = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 1;

    const [examTitle, setExamTitle] = useState('');
    const [examDescription, setExamDescription] = useState('');
    const [examDuration, setExamDuration] = useState('');

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
    
            const loadedColumns = jsonData[0];
            const loadedData = jsonData.slice(1);
    
            // Identify columns with non-null values
            const nonNullColumnsIndices = loadedColumns.reduce((indices, col, colIndex) => {
                const hasNonNull = loadedData.some(row => row[colIndex] !== null && row[colIndex] !== undefined && row[colIndex] !== '');
                if (hasNonNull) indices.push(colIndex);
                return indices;
            }, []);
    
            const nonNullColumns = nonNullColumnsIndices.map(index => loadedColumns[index]);
            const nonNullData = loadedData.map(row =>
                nonNullColumnsIndices.map(index => row[index])
            );
    
            setColumns(nonNullColumns);
            setData(nonNullData);
        };
    
        reader.readAsBinaryString(file);
    };
    
    const renderTableRows = () => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const selectedData = data.slice(startIndex, startIndex + rowsPerPage);

        return selectedData.map((row, rowIndex) => (
            <View key={rowIndex + startIndex} style={styles.tableRow}>
            {row.map((cell, cellIndex) => (
                <View key={cellIndex} style={[styles.tableCellContainer, { width: width / columns.length }]}>
                    <Text style={styles.tableCell}>
                        {cell}
                    </Text>
                </View>
            ))}
        </View>
        ));
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(data.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSubmit = async () => {
        const questions = data.map((row, index) => {
            // Retrieve correctAnswers from row[8] and ensure it's a string or an empty string
            const correctAnswers = row[8];
            
            // Convert correctAnswers to an array of numbers
            const correctAnswersList = Array.isArray(correctAnswers)
                ? correctAnswers.map(Number) // Convert if already an array
                : typeof correctAnswers === 'string'
                    ? correctAnswers.split(',').map(Number) // Split and convert if it's a string
                    : [Number(correctAnswers)]; // Handle cases where it's a single number or other type

              // Map the question type to the appropriate value
            let questionTypeEnum;
            switch (row[1]) {
                case 'Mullti Choice':
                    questionTypeEnum = 'MULTI_CHOICE';
                    break;
                case 'Multiple Choice':
                    questionTypeEnum = 'MULTIPLE_CHOICE';
                    break;
                default:
                    questionTypeEnum = 'UNKNOWN'; // Fallback value or handle as needed
                    break;
            }
        
            // Create answers array, filtering out any with empty or null answerText
            const answers = [
                { answerText: row[2], displayOrder: 1, isCorrect: correctAnswersList.includes(1) }, // Answer 1
                { answerText: row[3], displayOrder: 2, isCorrect: correctAnswersList.includes(2) }, // Answer 2
                { answerText: row[4], displayOrder: 3, isCorrect: correctAnswersList.includes(3) }, // Answer 3
                { answerText: row[5], displayOrder: 4, isCorrect: correctAnswersList.includes(4) }, // Answer 4
                { answerText: row[6], displayOrder: 5, isCorrect: correctAnswersList.includes(5) }, // Answer 5
                { answerText: row[7], displayOrder: 6, isCorrect: correctAnswersList.includes(6) }, // Answer 6
            ]//.filter(answer => answer.answerText); // Remove answers with empty, blank, or null answerText
    
            // If there are no valid answers, return null
            if (answers.length === 0) return null;
    
            // Create the question object if there are valid answers
            return {
                questionText: row[0], // Assuming first column is the question text
                questionTypeEnum: questionTypeEnum, // Assuming second column is the question type (as enum string)
                displayOrder: index + 1, // Just an example for display order
                explanation: row[9], // Assuming explanation is in the 9th column
                knowledgeArea: row[10], // Assuming knowledge area is in the 10th column
                answers, // Valid answers
            };
        }).filter(question => question !== null); // Remove any null entries

        const examData = {
            title: examTitle,
            description: examDescription,
            duration: examDuration,
            questions: questions,
        };

        console.log('Exam Data:', examData);

        try {
            let sitBaseUrl = 'https://digitalnexuspartners.ca/api'

            const response = await fetch(`${sitBaseUrl}/exam-management/exams/saveExamWithQuestionsAndAnswers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(examData),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            alert('Data submitted successfully');
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('Error submitting data');
        }
    };

    return (
        <ScrollView style={styles.container}>
            <View style={styles.fileInputContainer}>
                <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    style={styles.input}
                />
            </View>
            <View style={styles.formContainer}>
                <TextInput
                    style={styles.input}
                    placeholder="Exam Title"
                    value={examTitle}
                    onChangeText={setExamTitle}
                />
                <TextInput
                    style={styles.input}
                    placeholder="Exam Description"
                    value={examDescription}
                    onChangeText={setExamDescription}
                />
                <TextInput
                    style={styles.input}
                    placeholder="Exam Duration (in minutes)"
                    value={examDuration}
                    onChangeText={setExamDuration}
                    keyboardType="numeric"
                />
            </View>
            {columns.length > 0 && (
                <View style={styles.tableContainer}>
                    <ScrollView horizontal>
                        <View style={styles.table}>
                            <View style={styles.tableHeaderRow}>
                                {columns.map((col, index) => (
                                    <View key={index} style={[styles.tableCellContainer, { width: width / columns.length }]}>
                                        <Text style={styles.tableHeader}>{col}</Text>
                                    </View>
                                ))}
                            </View>
                            {renderTableRows()}
                        </View>
                    </ScrollView>
                </View>
            )}
            {data.length > rowsPerPage && (
                <View style={styles.pagination}>
                    <TouchableOpacity
                        onPress={handlePreviousPage}
                        disabled={currentPage === 1}
                        style={[
                            styles.paginationButton,
                            currentPage === 1 && styles.disabledButton,
                        ]}
                    >
                        <Text style={styles.paginationButtonText}>Previous</Text>
                    </TouchableOpacity>
                    <Text style={styles.pageNumber}>Page {currentPage}</Text>
                    <TouchableOpacity
                        onPress={handleNextPage}
                        disabled={currentPage === Math.ceil(data.length / rowsPerPage)}
                        style={[
                            styles.paginationButton,
                            currentPage === Math.ceil(data.length / rowsPerPage) && styles.disabledButton,
                        ]}
                    >
                        <Text style={styles.paginationButtonText}>Next</Text>
                    </TouchableOpacity>
                </View>
            )}
            <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
                <Text style={styles.submitButtonText}>Submit Data</Text>
            </TouchableOpacity>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#f2f2f2',
    },
    fileInputContainer: {
        marginBottom: 20,
        alignItems: 'center',
    },
    formContainer: {
        marginBottom: 20,
    },
    input: {
        padding: 8,
        fontSize: 14,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#007BFF',
        backgroundColor: '#fff',
        marginBottom: 10,
    },
    tableContainer: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#ddd',
        backgroundColor: '#fff',
        borderRadius: 5,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 3,
    },
    tableHeaderRow: {
        flexDirection: 'row',
        backgroundColor: '#007BFF',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    tableRow: {
        flexDirection: 'row',
        backgroundColor: '#f9f9f9',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    tableCellContainer: {
        padding: 10,
        borderRightWidth: 1,
        borderRightColor: '#ddd',
    },
    tableHeader: {
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 12,
    },
    tableCell: {
        textAlign: 'left',
        color: '#333',
        fontSize: 14,
    },
    pagination: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20,
    },
    paginationButton: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: '#007BFF',
        borderRadius: 5,
        marginHorizontal: 5,
    },
    disabledButton: {
        backgroundColor: '#ccc',
    },
    paginationButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 12,
    },
    pageNumber: {
        alignSelf: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#333',
    },
    submitButton: {
        marginTop: 20,
        paddingVertical: 12,
        backgroundColor: '#28a745',
        borderRadius: 5,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 4,
        elevation: 3,
    },
    submitButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
    }
});

export default ExcelViewerWithPagination;
