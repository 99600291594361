import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Alert, Dimensions, ScrollView } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css'; // Import the CSS file for styling

const Registration = () => {
  const { width, height } = Dimensions.get('window');
  const navigate = useNavigate();  // Correctly initialize useNavigate
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  // Form state variables
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Function to handle form submission
  const handleRegister = () => {

     // Check if all required fields are filled
    if (!firstName.trim()) {
      toast.error('First Name is required');
      return;
    }

    if (!lastName.trim()) {
      toast.error('Last Name is required');
      return;
    }

    if (!mobileNumber.trim()) {
      toast.error('Mobile Number is required');
      return;
    }

    if (!email.trim()) {
      toast.error('Email is required');
      return;
    }

    if (!password.trim()) {
      toast.error('Password is required');
      return;
    }

    if (!confirmPassword.trim()) {
      toast.error('Confirm Password is required');
      return;
    }

    if (!emailRegex.test(email)) {
      console.log('Invalid email address'); // Debugging line
      toast.error('Please enter a valid email address');
      return;
    }

    if (password !== confirmPassword) {
      console.log('Passwords do not match'); // Debugging line
      toast.error('Passwords do not match');
      return;
    }

    const requestData = {
      firstNameEnglish: firstName,
      secondNameEnglish: lastName,
      mobileNumber: mobileNumber,
      email: email,
      password: password,
      userType: 'CUSTOMER' // assuming the user type is customer
    };

//    let localUrl = 'http://localhost:8080/api/identity/customer/create'
   // let sitUrl = 'https://108.181.193.101:443/api/identity/customer/create'
    let sitUrl = 'https://digitalnexuspartners.ca/api/identity/customer/create'

    
    axios.post(sitUrl, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      console.log('Response Data:', response.data);
      toast.success('Customer registered successfully');
      setTimeout(() => {
        navigate('/login', { state: { email: email } });
      }, 3000);
    })
    .catch(error => {
      console.error('Error:', error.response.data);
      console.error('Error:', error.response.status);
      if (error.response && error.response.data && error.response.data.errorMessage) {
        toast.error(error.response.data.errorMessage); // Display backend error message
      } else {
        toast.error('Failed to register customer');
      }
    });
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Logo Section */}
      <View style={styles.logoContainer}>
        <Text style={styles.logoText}>Digital Nexus Partners</Text>
      </View>

      {/* Form Section */}
      <View style={styles.formContainer}>
        <Text style={styles.title}>Registration</Text>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>First Name</Text>
          <TextInput 
            style={styles.input} 
            placeholder="Enter your first name" 
            value={firstName}
            onChangeText={setFirstName}
          />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Last Name</Text>
          <TextInput 
            style={styles.input} 
            placeholder="Enter your last name" 
            value={lastName}
            onChangeText={setLastName}
          />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Mobile Number</Text>
          <TextInput 
            style={styles.input} 
            placeholder="Enter your mobile number" 
            keyboardType="phone-pad" 
            value={mobileNumber}
            onChangeText={setMobileNumber}
          />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Email</Text>
          <TextInput 
            style={styles.input} 
            placeholder="Enter your email address" 
            keyboardType="email-address" 
            value={email}
            onChangeText={setEmail}
          />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Password</Text>
          <View style={styles.passwordContainer}>
            <TextInput 
              style={styles.passwordInput} 
              placeholder="Enter your password" 
              secureTextEntry={!showPassword} 
              value={password}
              onChangeText={setPassword}
            />
            <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
              <Text style={styles.showPasswordText}>{showPassword ? 'Hide' : 'Show'}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Confirm Password</Text>
          <View style={styles.passwordContainer}>
            <TextInput 
              style={styles.passwordInput} 
              placeholder="Confirm your password" 
              secureTextEntry={!showConfirmPassword} 
              value={confirmPassword}
              onChangeText={setConfirmPassword}
            />
            <TouchableOpacity onPress={() => setShowConfirmPassword(!showConfirmPassword)}>
              <Text style={styles.showPasswordText}>{showConfirmPassword ? 'Hide' : 'Show'}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <TouchableOpacity onPress={() => navigate('/login')} style={styles.loginButton}>
          <Text style={styles.loginText}>Already have an account? Login</Text>
        </TouchableOpacity>
        <ToastContainer />
        <TouchableOpacity style={styles.registerButton} onPress={handleRegister}>
          <Text style={styles.buttonText}>Register</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingHorizontal: 30,
    paddingTop: 50,
    paddingBottom: 20,
    textAlign: 'center',
  },
  logoContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  logoText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#133e8a',
   // fontFamily: 'Lucida Handwriting', // Replace with your custom font
    marginBottom: 20, // Adjust margin as needed
  },
  title: {
    fontSize: 24,
    marginTop: 20,
    color: '#133e8a',
    fontWeight: 'bold',
    marginBottom: 24,
  },
  formContainer: {
    width: '100%',
  },
  inputContainer: {
    marginBottom: 12,
  },
  label: {
    marginBottom: 6,
    color: 'gray',
    fontSize: 16,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 8,
    fontSize: 16,
    color: 'black',
    width: '100%',
    borderRadius: 8,  // Adjust this value to change the amount of curve
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 8,
  },
  passwordInput: {
    flex: 1,
    height: 40,
    paddingHorizontal: 8,
    fontSize: 16,
    color: 'black',
  },
  registerButton: {
    backgroundColor: '#17499F',
    paddingVertical: 12,
    paddingHorizontal: 24,
    marginTop: 20,
    width: '100%',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
  loginText: {
    marginTop: 20,
    color: '#15826f',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  showPasswordText: {
    color: '#15826f',
    textDecorationLine: 'underline',
    padding: 10,
  },
});

export default Registration;
