import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './component/login/Login';
import Registration from './component/registration/Registration';
import ExamScreen from './component/exam/ExamScreen';
import CustomerHome from './component/customer-home/CustomerHome';
import AdminHome from './component/admin-home/AdminHome';
import ExamResultScreen from './component/exam-result/ExamResultScreen';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component
import ForgetPassword from './component/forget-password/ForgetPassword';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/" element={<PrivateRoute element={CustomerHome} />} />
      <Route path="/customer-home" element={<PrivateRoute element={CustomerHome} />} />
      <Route path="/admin-home" element={<AdminHome />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/exam/:examId" element={<PrivateRoute element={ExamScreen} />} />
      <Route path="/exam-result/:examId" element={<PrivateRoute element={ExamResultScreen} />} />
    </Routes>
  </Router>
);




reportWebVitals();
