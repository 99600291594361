import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS file for styling

const CustomerHome = () => {
  const [availableExams, setAvailableExams] = useState([]);
  const [examHistory, setExamHistory] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const navigate = useNavigate();  // Correctly initialize useNavigate

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    navigate('/login');
  };

  useEffect(() => {
    // Fetch the customer name from localStorage
    const name = localStorage.getItem('firstName');
    const email = localStorage.getItem('email');

    if (name) {
      setCustomerName(name);
    } else {
      setCustomerName('');
    }

    let sitBaseUrl = 'https://digitalnexuspartners.ca/api'

    
    // Fetch available exams
    fetch(`${sitBaseUrl}/exam-management/exams/getAvailableExams`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAvailableExams(data.availableExams);
      })
      .catch(error => {
        console.error('Error fetching exams:', error);
        toast.error('Error fetching exams');
      });
    // Fetch exam history
      if (email) {
        // Fetch customer exam history using the email from local storage
        fetch(`${sitBaseUrl}/exam-management/customer-exams/getCustomerExams?email=${email}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => setExamHistory(data.customerExamHistory))
          .catch(error => {
            console.error('Error fetching exam history:', error);
            toast.error('Error fetching exam history');
          });
      } else {
        toast.error('No email found');
      }
    }, []);

  const handleTakeExam = (examId) => {
    navigate(`/exam/${examId}`); // Navigate with examId parameter
  };

  const handleViewResult = (customerExamId) => {
    navigate(`/exam-result/${customerExamId}`); // Navigate to exam result page with customerExamId parameter
  };

  const handleResumeExam = (exam) => {
    console.log('exam data ', exam)
    navigate(`/exam/${exam.examId}`); // Navigate to resume exam page with customerExamId parameter
  };

  return (
    <View style={styles.container}>
      {/* Header */}
      <View style={styles.headerContainer}>
        <View style={styles.headerTextContainer}>
          <Text style={styles.headerText}>Digital Nexus Partners</Text>
          <Text style={styles.customerName}>Welcome, {customerName}</Text>
        </View>
        <ToastContainer
                  position="top-right"  // Position of the toast
                  autoClose={3000}      // Auto close after 5 seconds
                />
        <TouchableOpacity onPress={handleLogout} style={styles.logoutButton}>
          <Text style={styles.logoutButtonText}>Logout</Text>
        </TouchableOpacity>
      </View>

      {/* Content */}
      <ScrollView style={styles.content}>
        <View style={styles.innerContainer}>
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>Available Exams</Text>
            {availableExams.map(exam => (
              <TouchableOpacity
                key={exam.id}
                style={styles.examCard}
                onPress={() => handleTakeExam(exam.id)}
              >
                <Text style={styles.examTitle}>{exam.title}</Text>
                <Text style={styles.examButton}>Take Exam</Text>
              </TouchableOpacity>
            ))}
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionHeader}>Your Exam History</Text>
            {examHistory.map(exam => (
              <View key={exam.id} style={styles.historyCard}>
                <Text style={styles.historyTitle}>{exam.title}</Text>
                <Text style={styles.historyDetails}>
                  {exam.date} - Score: {exam.score !== null && exam.score !== undefined ? exam.score : 'Not Available'}
                </Text>
                {/* <TouchableOpacity 
                  style={styles.resultButton}
                  onPress={() => handleViewResult(exam.customerExamId)}>
                  <Text style={styles.resultButtonText}>Resum Exam</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.resultButton}
                  onPress={() => handleViewResult(exam.customerExamId)}>
                  <Text style={styles.resultButtonText}>View Result</Text>
                </TouchableOpacity> */}
                {!exam.isCompleted ? (
                  
                  <TouchableOpacity
                    style={styles.resumeButton}
                    onPress={() => handleResumeExam(exam)}
                  >
                    <Text style={styles.resultButtonText}>Resume Exam</Text>
                  </TouchableOpacity>


                ) : (
                  <TouchableOpacity
                    style={styles.resultButton}
                    onPress={() => handleViewResult(exam.customerExamId)}
                  >
                    <Text style={styles.resultButtonText}>View Result</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </View>
        </View>
      </ScrollView>

      {/* Footer */}
      <View style={styles.footer}>
        <Text style={styles.footerText}>© 2024 Digital Nexus Partners. All rights reserved.</Text>
      </View>
    </View>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f0f0f0',
  },
  headerContainer: {
    backgroundColor: '#17499F',
    paddingVertical: 20,
    paddingHorizontal: width > 600 ? 40 : 20,
    marginBottom: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTextContainer: {
    flex: 1,
  },
  headerText: {
    fontSize: width > 600 ? 40 : 32,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  customerName: {
    fontSize: width > 600 ? 24 : 20,
    color: '#FFFFFF',
    marginTop: 10,
  },
  logoutButton: {
    backgroundColor: '#ff4444',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 3,
  },
  logoutButtonText: {
    fontSize: width > 600 ? 18 : 16,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  content: {
    flex: 1,
    paddingHorizontal: width > 600 ? 40 : 20,
  },
  innerContainer: {
    paddingVertical: 20,
  },
  section: {
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: width > 600 ? 28 : 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
  },
  examCard: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    marginBottom: 10,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  examTitle: {
    fontSize: width > 600 ? 20 : 18,
    color: '#333',
  },
  examButton: {
    fontSize: width > 600 ? 18 : 16,
    color: '#1e90ff',
  },
  historyCard: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    marginBottom: 10,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  historyTitle: {
    fontSize: width > 600 ? 20 : 18,
    color: '#333',
  },
  historyDetails: {
    fontSize: width > 600 ? 18 : 16,
    color: '#777',
    marginTop: 5,
  },
  resultButton: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#1e90ff',
    borderRadius: 5,
  },
  resumeButton: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#338205',
    borderRadius: 5,
  },
  resultButtonText: {
    fontSize: width > 600 ? 18 : 16,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  footer: {
    backgroundColor: '#17499F',
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    fontSize: width > 600 ? 14 : 12,
    color: '#FFFFFF',
  },
});

export default CustomerHome;
