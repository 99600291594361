import React, { useState, useEffect } from 'react';
import Header from '../header/Header'; // Import the reusable Header component
import './ExamResultScreen.css'; // Import your component-specific styles
import { useNavigate, useParams } from 'react-router-dom';

const ExamResultScreen = () => {
  const { examId } = useParams(); // Retrieve exam ID from route params
  const navigate = useNavigate();  // Correctly initialize useNavigate
  const [examTitle, setExamTitle] = useState('');
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [score, setScore] = useState('');
  const [questionResults, setQuestionResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [modalContent, setModalContent] = useState({ title: '', content: '' }); // Modal content

  useEffect(() => {
    const fetchExamResult = async () => {
      let sitBaseUrl = 'https://digitalnexuspartners.ca/api'

      const url = `${sitBaseUrl}/exam-management/exam-result/getExamResult/${examId}`;
      
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setExamTitle(data.examTitle);
        setTotalQuestions(data.totalQuestions);
        setCorrectAnswers(data.correctAnswers);
        setScore(data.score);
        setQuestionResults(data.questionResults);
      } catch (error) {
        console.error('Error fetching exam result:', error);
        setError('Failed to load exam results.');
      } finally {
        setLoading(false);
      }
    };

    fetchExamResult();
  }, [examId]);

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="exam-result-container">
      <Header title="Exam Result" />

      <div className="exam-details">
        <h2>{examTitle}</h2>
        <p>Score: {correctAnswers} out of {totalQuestions}</p>
        <p>Percentage: {score}%</p>

        <div className="question-results">
          {questionResults.map((result, index) => (
            <div key={index} className="question-result">
              <h3>{result.questionText}</h3>

              {/* Display user answers */}
              <p>Your Answers:</p>
              <ul>
                {result.userAnswer.map((answer, idx) => (
                  <li
                    key={idx}
                    style={{
                      color: result.correctAnswer.includes(answer) ? 'green' : 'red'
                    }}
                  >
                    {answer}
                  </li>
                ))}
              </ul>

              {/* Display correct answers */}
              <p>Correct Answers:</p>
              <ul>
                {result.correctAnswer.map((correctAnswer, idx) => (
                  <li key={idx}>
                    {correctAnswer}
                  </li>
                ))}
              </ul>

              {/* Knowledge Area Link */}
              {result.knowledgeArea && (
                <p className="knowledge-area-link" onClick={() => openModal('Knowledge Area', result.knowledgeArea)}>
                  Knowledge Area
                </p>
              )}

              {/* Explanation */}
              {result.explanation && (
                <p className="knowledge-area-link" onClick={() => openModal('Explanation', result.explanation)}>
                  Explanation
                </p>
              )}
            </div>
          ))}
        </div>

        <button className="back-button" onClick={() => navigate('/customer-home')}>
          Back to Dashboard
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{modalContent.title}</h2>
            <p>{modalContent.content}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamResultScreen;
