import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Modal } from 'react-native';
import { useParams, useNavigate } from 'react-router-dom';

const ExamScreen = () => {
  const navigate = useNavigate();
  const { examId } = useParams();
  const [examDetails, setExamDetails] = useState(null);
  const [customerExamId, setCustomerExamId] = useState(null);
  const [answers, setAnswers] = useState({});
  const [timer, setTimer] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const [totalQuestions, setTotalQuestions] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [notAnsweredQuestions, setNotAnsweredQuestions] = useState(0);
  const [isPaused, setIsPaused] = useState(false);


  const handleViewResult = (customerExamId) => {
    navigate(`/exam-result/${customerExamId}`);
  };

  useEffect(() => {

    const email = localStorage.getItem('email');
    let sitBaseUrl = 'https://digitalnexuspartners.ca/api'

    const fetchExamDetails = async () => {
      try {
        const response = await fetch(`${sitBaseUrl}/exam-management/exams/details/${examId}`);
        const data = await response.json();
        setExamDetails(data);
        setTimer(data.duration * 60);
        setTotalQuestions(data.questions.length);

        const customerExamResponse = await fetch(`${sitBaseUrl}/exam-management/customer-exams/createCustomerExam`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email, 
            examId: examId,
          }),
        });
        
        const customerExamData = await customerExamResponse.json();
        setCustomerExamId(customerExamData.customerExamId);
      } catch (error) {
        console.error('Error fetching exam details or creating customer exam:', error);

      }
    };

    fetchExamDetails();
  }, [examId]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleOptionChange = (questionId, optionId) => {
    const questionType = examDetails.questions[currentQuestionIndex].questionTypeEnum;
    console.log('questionType =', questionType);
    if (questionType === 'MULTIPLE_CHOICE') {
      // For MULTIPLE_CHOICE, allow only one option
      setAnswers({ ...answers, [questionId]: optionId });
    } else if (questionType === 'MULTI_CHOICE') {
      // For MULTI_CHOICE, allow multiple options
      setAnswers((prevAnswers) => {
        const currentAnswers = prevAnswers[questionId] || [];
        if (currentAnswers.includes(optionId)) {
          // Remove the option if it is already selected
          return {
            ...prevAnswers,
            [questionId]: currentAnswers.filter(id => id !== optionId),
          };
        } else {
          // Add the new option
          return {
            ...prevAnswers,
            [questionId]: [...currentAnswers, optionId],
          };
        }
      });
    }
  };

  const handleSubmit = async () => {
    try {
        let sitBaseUrl = 'https://digitalnexuspartners.ca/api'
        const response = await fetch(`${sitBaseUrl}/exam-management/customer-answers/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerExamId: customerExamId,
          questionAnswers: Object.entries(answers).map(([questionId, answerIds]) => ({
            questionId: parseInt(questionId),
            answerIds: Array.isArray(answerIds) ? answerIds : [answerIds], // Ensure answerIds is an array
          })),
        }),
      });
      if (response.ok) {
        console.log('Submitted answers successfully');
        handleViewResult(customerExamId);
      } else {
        console.error('Failed to submit answers');
      }
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const updateQuestionCounts = () => {
    const total = examDetails.questions.length;
    const answered = Object.keys(answers).length;
    const notAnswered = total - answered;
    setAnsweredQuestions(answered);
    setNotAnsweredQuestions(notAnswered);
  };

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => {
      const nextIndex = Math.min(prevIndex + 1, examDetails.questions.length - 1);
      updateQuestionCounts(); // Update counts after moving to the next question
      return nextIndex;
    });
  };

  const handleBack = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handlePause = async () => {
    setIsPaused(!isPaused);
    if (!isPaused) {
      openModal("Exam Paused", "You can resume the exam when you're ready.");
    }

  };

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setModalVisible(true);
  };
  
  if (!examDetails) {
    return (
      <View style={styles.container}>
        <Text>Loading exam details...</Text>
      </View>
    );
  }

  const currentQuestion = examDetails.questions[currentQuestionIndex];

  return (
    <ScrollView style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.examTitle}>{examDetails.title}</Text>
        <TouchableOpacity onPress={handlePause} >
          <Text style={styles.pauseButton}>Pause</Text>
        </TouchableOpacity>
        <Text style={styles.timerText}>{formatTime(timer)}</Text>
      </View>
  
      <View style={styles.summaryContainer}>
        <Text style={styles.summaryText}>Total Questions: {totalQuestions}</Text>
        <Text style={styles.summaryText}>Answered: {answeredQuestions}</Text>
        <Text style={styles.summaryText}>Not Answered: {notAnsweredQuestions}</Text>
      </View>

      <View style={styles.questionContainer}>
        <Text style={styles.questionText}>{currentQuestion.questionText}</Text>
        {currentQuestion.answers.map((answer) => (
          <TouchableOpacity
            key={answer.id}
            style={styles.optionContainer}
            onPress={() => handleOptionChange(currentQuestion.id, answer.id)}
          >
            <Text style={styles.optionText}>
              {currentQuestion.questionTypeEnum === 'MULTIPLE_CHOICE' ?
                (answers[currentQuestion.id] === answer.id ? '✔️ ' : '⬜️ ') :
                (answers[currentQuestion.id] && answers[currentQuestion.id].includes(answer.id) ? '✔️ ' : '⬜️ ')
              }
              {answer.answerText}
            </Text>
          </TouchableOpacity>
        ))}
      </View>

      <View style={styles.navigationButtons}>
        <TouchableOpacity style={styles.navButton} onPress={handleBack} disabled={currentQuestionIndex === 0}>
          <Text style={styles.navButtonText}>Back</Text>
        </TouchableOpacity>
        {currentQuestionIndex < examDetails.questions.length - 1 ? (
          <TouchableOpacity style={styles.navButton} onPress={handleNext}>
            <Text style={styles.navButtonText}>Next</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
            <Text style={styles.submitButtonText}>Submit</Text>
          </TouchableOpacity>
        )}
      </View>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>{modalContent.title}</Text>
            <Text style={styles.modalText}>{modalContent.content}</Text>
            <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f0f0f0',
  },
  headerContainer: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginBottom: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  examTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#17499F',
  },
  timerText: {
    fontSize: 18,
    color: '#FF0000',
    fontWeight: 'bold',
  },
  summaryContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  summaryText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  questionContainer: {
    marginBottom: 20,
    padding: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  questionText: {
    fontSize: 18,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#17499F',
    textDecorationLine: 'underline',
    marginBottom: 10,
  },
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  optionText: {
    fontSize: 16,
  },
  navigationButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  navButton: {
    backgroundColor: '#17499F',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    flex: 1,
    marginRight: 10,
  },
  pauseButton: {
    backgroundColor: '#FF6347', // Tomato color for visibility
    paddingVertical: 12,          // Vertical padding
    paddingHorizontal: 24,        // Horizontal padding
    borderRadius: 8,             // Rounded corners
    alignItems: 'center',        // Centering text inside button
    justifyContent: 'center',    // Ensures the button's content is centered
    elevation: 5,                // Adds shadow for Android
    shadowColor: '#000',         // Shadow color for iOS
    shadowOffset: { width: 0, height: 4 },  // Shadow positioning for iOS
    shadowOpacity: 0.1,          // Shadow opacity for iOS
    shadowRadius: 6,             // Shadow blur radius for iOS
    marginLeft: 'auto',          // Push the button to the right
    color: '#FFFFFF',            // White text
    fontSize: 16,                // Font size
    fontWeight: 'bold',   
  },
  navButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  submitButton: {
    backgroundColor: '#17499F',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    flex: 1,
  },
  submitButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontSize: 16,
    marginBottom: 20,
  },
  closeButton: {
    backgroundColor: '#17499F',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  closeButtonText: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});


export default ExamScreen;
