import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Dimensions, ScrollView, ActivityIndicator } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS file for styling
import axios from 'axios';


const Login = () => {
  const { width, height } = Dimensions.get('window');
  const navigate = useNavigate();  // Correctly initialize useNavigate

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State for managing the loader
  const [showPassword, setShowPassword] = useState(false);


  const handleLogin = () => {
    // Validate email and password
    if (email.trim() === '') {
      toast.error('Email cannot be empty');
      return;
    }

    if (password.trim() === '') {
      toast.error('Password cannot be empty');
      return;
    }

    const requestData = {
      email: email,
      password: password    
    };

    setLoading(true); // Start loader

   // let localUrl = 'http://localhost:8080/api/identity/auth/login'
    let sitUrl = 'https://digitalnexuspartners.ca/api/identity/auth/login'

    // fetch(sitUrl, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(requestData),
    // })
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then(data => {
    //     console.log('Response Data:', data); // Debugging line
    //     if (data) {
    //       localStorage.setItem('token', data.token);
    //       localStorage.setItem('email', data.email);
    //       localStorage.setItem('userType', data.userType);
    //       if (data.userType === 'CUSTOMER') {
    //         localStorage.setItem('firstName', data.firstName);
    //         navigate('/customer-home');
    //       } else if (data.userType === 'ADMIN') {
    //         navigate('/admin-home');
    //       } else {
    //         toast.error('Unknown user type');
    //       }
    //     }
    //   })
    //   .catch(error => {
    //     console.error('Error:', error); // Debugging line
    //     toast.error('Failed to login');
    //   })
    //   .finally(() => {
    //     setLoading(false); // Stop loader
    //   });
    // };
  

      axios.post(sitUrl, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
       // console.log('Response Data:', data); // Debugging line
        if (res) {
          let data = res.data
          console.log('data',data)
          localStorage.setItem('token', data.token);
          localStorage.setItem('email', data.email);
          localStorage.setItem('userType', data.userType);
          if (data.userType === 'CUSTOMER') {
            localStorage.setItem('firstName', data.firstName);
            navigate('/customer-home');
          } else if (data.userType === 'ADMIN') {
            navigate('/admin-home');
          } else {
            toast.error('Unknown user type');
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
        console.error('Error:', error.response.status);
        if (error.response && error.response.data && error.response.data.errorMessage) {
          toast.error(error.response.data.errorMessage); // Display backend error message
        } else {
          toast.error('Failed to login');
        }
      }).finally(() => {
        // Code that will run regardless of success or failure
        console.log('Request has completed (success or failure)');
         setLoading(false); // Stop loader

        // Any cleanup code or common actions after the request is done can go here
      });
    };
  
  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Logo Section */}
      <View style={styles.logoContainer}>
       <Text style={styles.logoText}>Digital Nexus Partners</Text> 
      </View>

      {/* Form Section */}
      <View style={styles.formContainer}>
        <Text style={styles.title}>Login</Text>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Email</Text>
          <TextInput 
            style={styles.input} 
            placeholder="Enter your email address" 
            keyboardType="email-address" 
            value={email} 
            onChangeText={setEmail} 
          />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>Password</Text>
          <View style={styles.passwordContainer}>
            <TextInput 
              style={styles.input} 
              placeholder="Enter your password" 
              secureTextEntry={!showPassword}  
              value={password} 
              onChangeText={setPassword} 
            />
            <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
                <Text style={styles.showPasswordText}>{showPassword ? 'Hide' : 'Show'}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <TouchableOpacity onPress={() => navigate('/register')} style={styles.loginButton}>
          <Text style={styles.registerText}>Don't have an account? Create an account</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigate('/forget-password')} style={styles.loginButton}>
          <Text style={styles.registerText}>Forget Password</Text>
        </TouchableOpacity>
        <ToastContainer
          position="top-right"  // Position of the toast
          autoClose={3000}      // Auto close after 3 seconds
        />
        {/* Show loader if loading is true */}
        {loading ? (
          <ActivityIndicator size="large" color="#133e8a" style={styles.loader} />
        ) : (
          <TouchableOpacity style={styles.registerButton} onPress={handleLogin}>
            <Text style={styles.buttonText}>Login</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingHorizontal: 30,
    paddingTop: 50,
    paddingBottom: 20,
    textAlign: 'center',
  },
  logoContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  logoText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#133e8a',
    marginBottom: 20, // Adjust margin as needed
  },
  title: {
    fontSize: 24,
    marginTop: 20,
    color: '#133e8a',
    fontWeight: 'bold',
    marginBottom: 24,
  },
  formContainer: {
    width: '100%',
  },
  inputContainer: {
    marginBottom: 12,
  },
  label: {
    marginBottom: 6,
    color: 'gray',
    fontSize: 16,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 8,
    fontSize: 16,
    color: 'black',
    width: '100%',
    borderRadius: 8,  // Adjust this value to change the amount of curve
  },
  registerButton: {
    backgroundColor: '#133e8a',
    paddingVertical: 12,
    paddingHorizontal: 24,
    marginTop: 20,
    width: '100%',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
  registerText: {
    marginTop: 20,
    color: '#15826f',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  loader: {
    marginTop: 20,
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 8,
  },
  showPasswordText: {
    color: '#15826f',
    textDecorationLine: 'underline',
    padding: 10,
  },
});

export default Login;
